import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Impressum from './ImpressumComponent';

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const languages = [
    { code: "en", name: "English", icon: "🇬🇧" },
    { code: "de", name: "Deutsch", icon: "🇩🇪" },
    { code: "fr", name: "Français", icon: "🇫🇷" },
  ];  

  const getCurrentLang = () => {
    const cur = i18n.language;
    for(let i=0;i<languages.length;i++) {
      if(languages[i].code===cur) {
        return languages[i];
      }
    }
    return languages[0];
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLang());

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language.code);
  };

  const handleImpressumClick = () => {
    setShowModal(true);
  };

  const handleImpressumClose = () => {
    setShowModal(false);
  };

  return (
    <>
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
      <Navbar.Brand href="/">
            <img
              src="img/alpinemeteo-logo-icon.png"
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="AlpineMeteo"
            />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">AlpineMeteo</Nav.Link>
            <NavDropdown title={t("nav.maps")} id="basic-nav-dropdown">
              <NavDropdown.Item href="/map">{t("nav.maps.all")}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/tempmap">{t("nav.maps.temp")}</NavDropdown.Item>
              <NavDropdown.Item href="/hsmap">{t("nav.maps.hs")}</NavDropdown.Item>
              <NavDropdown.Item href="/hs24map">{t("nav.maps.hs24h")}</NavDropdown.Item>
              <NavDropdown.Item href="/vwmap">{t("nav.maps.vw")}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/shadow">{t("nav.maps.shade")}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/compare">{t("nav.compare")}</Nav.Link>
            <Nav.Link href="/hsgam">{t("nav.hsgam")}</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link onClick={handleImpressumClick}>{t("nav.contact")}</Nav.Link>
            <NavDropdown
              style={{"maxWidth":"1%"}} 
              title={<span>{selectedLanguage.icon}</span>}
              id='collapsible-nav-dropdown'>
              {languages.map((language) => (
                <NavDropdown.Item
                  key={language.code}
                  style={{"textAlign":"center"}}
                  onClick={() => handleLanguageSelect(language)}
                >
                  {language.icon}   {language.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Impressum open={showModal} onClose={() => { handleImpressumClose(); }} />
    </>
  );
};

export default Navigation;

