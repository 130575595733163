import React from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { useTranslation } from "react-i18next";
import './index.css';

const HomeComponent = () => {
  const { trackPageView } = useMatomo()
  const { t } = useTranslation();

  // Track page view
  React.useEffect(() => {
    trackPageView()
  }, [trackPageView])

  return (
    <div class="bgdiv">
    <div class="maindiv">
      <div style={{"textAlign": "center"}}>
      <img style={{"marginTop": "30px", "maxWidth": "80%","maxHeight": "130px" }} alt="alpine meteo logo" src="https://alpinemeteo.com/img/alpinemeteo-logo-large-white.png"></img>
      </div>
      <div class="textblock">
        <p>
          {t("main.intro.p1")}
        </p>
        <p>
          <b>{t("main.intro.p2")}</b><br></br>
            {t("main.intro.p3")}
          </p>
        <p>

          {t("main.intro.p4")}
        </p>
        <p>
          {t("main.intro.p6")}
        </p>
        <p>
          {t("main.intro.p7")}
        </p>
        <p>
          {t("main.intro.p8")}
        </p>
      </div>
      <div class="textblock">
        <h6>{t("main.support")} <a href="https://www.ifalp.org">IFALP</a>:</h6>
        <a href="https://www.ifalp.org"><img style={{ "textAlign": "left", "maxHeight": "100px" }} src="https://alpinemeteo.com/img/ifalp.png" alt="ifalp logo"></img></a>
      </div>
      <div class="textblock">
      <h6>{t("main.sources")}</h6>
      <ul>
        <li><a href="https://lawine.tirol.gv.at" rel="noreferrer" target="_blank">LWD Tirol</a></li>
        <li><a href="https://www.geosphere.at" rel="noreferrer" target="_blank">GeoSphere Austria</a></li>
        <li><a href="https://lawine.salzburg.at" rel="noreferrer" target="_blank">LWD Salzburg</a></li>
        <li><a href="https://www.lawinenwarndienst-bayern.de" rel="noreferrer" target="_blank">Lawinenwarndienst Bayern</a></li>
        <li><a href="https://www.slf.ch" rel="noreferrer" target="_blank">WSL- Institut für Schnee und Lawinenforschung SLF</a></li>
        <li><a href="https://www.lawis.at" rel="noreferrer" target="_blank">lawis.at</a></li>
        <li><a href="https://www.salzburg.gv.at" rel="noreferrer" target="_blank">Land Salzburg</a></li>
        <li><a href="https://www.tirol.gv.at" rel="noreferrer" target="_blank">Land Tirol</a></li>
        <li><a href="http://www.iav-portal.com" rel="noreferrer" target="_blank">iav portal</a></li>
        <li><a href="https://cf.regione.vda.it" rel="noreferrer" target="_blank">CFR Valle d'Aosta</a></li>
        <li><a href="https://www.arpa.piemonte.it" rel="noreferrer" target="_blank">ARPA Piemonte</a></li>
        <li><a href="https://www.arpal.liguria.it" rel="noreferrer" target="_blank">ARPAL</a></li>        
      </ul>
      <p>
      {t("main.legdisc")}
      </p>
      </div>
    </div >
    </div>
  );
};

export default HomeComponent;
